export const urlBackend = (() => {
  switch (process.env.REACT_APP_BACKEND_ENV) {
    case 'PROD':
      return process.env.REACT_APP_BACKEND_PROD;
    case 'STAGING':
      return process.env.REACT_APP_BACKEND_STAGING;
    case 'LOCAL':
      return process.env.REACT_APP_BACKEND_LOCAL;
    default:
      throw new Error(`Ambiente sconosciuto: ${process.env.REACT_APP_BACKEND_ENV}`);
  }
})();