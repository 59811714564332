// ** Auth Endpoints
import {urlBackend} from "../../../utility/hooks/urlService";


export default {
  loginEndpoint: `${urlBackend}/auth/sign_in`,
  registerEndpoint: `${urlBackend}/auth/register`,
  refreshEndpoint: `${urlBackend}/auth/sign_in`,
  logoutEndpoint: `${urlBackend}/auth/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'JWT',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'token'
}
